jQuery(document).ready(function(jQuery){

  /*/ variable decalarations /*/
  var last_scroll = 0;

  /*/ Ajax functions /*/
  jQuery(document).on('click','.sage-load-more:not(.loading)', function(){

    var that = jQuery(this);
    var page = jQuery(this).data('page');
    var newPage = page+1;
    var ajaxurl = that.data('url');
    var prev = that.data('prev');
    var archive = that.data('archive');

    //load previous button has a value of 1
    if( typeof prev === 'undefined' ){
      prev = 0;
    }
    //if not archive and is blog page
    if( typeof archive === 'undefined' ){
      archive = 0;
    }

    //prevent user double clicking and animate
    that.addClass('loading');
    that.find('.sage-icon').addClass('spin');

    jQuery.ajax({
      url : ajaxurl,
      type : 'post',
      data : {
        page : page,
        prev : prev,
        archive : archive,
        action: 'sage_load_more'
      },
      error : function( response ){
        console.log(response);
      },
      success : function( response ){
        if( response == 0 ){
          jQuery('.sage-posts-container').append( '<div class="text-center"><h3>You reached the end of the line!</h3><p>No more posts to load.</p></div>' );
          that.slideUp(320);
        } else {
          setTimeout(function(){
            if( prev == 1 ){
              //previous button clicked
              jQuery('.sage-posts-container').prepend( response );
              newPage = page-1;
            } else {
              //next button clicked
              jQuery('.sage-posts-container').append( response );
            }

            if( newPage == 1 ){
              //no more previous pages
              that.slideUp(320);
            } else {
              //sets data-page for either previous or next button
              that.data('page', newPage);
              that.removeClass('loading');
              that.find('.sage-icon').removeClass('spin');
            }
          }, 1000);
        }
      }
    });
  });

  /*/ AJAX - Reviews /*/
  jQuery(document).on('click','.sage-reviews-more:not(.loading)', function(){

    var that = jQuery(this);
    var page = jQuery(this).data('page');
    var newPage = page+1;
    var ajaxurl = that.data('url');
    var id = jQuery('.form-submit input:nth(1)').attr('value');
    //prevent user double clicking and animate
    that.addClass('loading');
    that.find('.sage-icon').addClass('spin');

    jQuery.ajax({
      url : ajaxurl,
      type : 'post',
      data : {
        page : page,
        id : id,
        action: 'sage_reviews_more'
      },
      error : function( response ){
        console.log(response);
      },
      success : function( response ){
        if( response == 0 ){
          that.slideUp(320);
        } else {
          setTimeout(function(){
            jQuery('#reviews .commentlist').append( response );

            that.data('page', newPage);
            that.removeClass('loading');
            that.find('.sage-icon').removeClass('spin');
          }, 1000);
        }
      }
    });
  });

  /*/ AJAX - Testimonials /*/
  jQuery(document).on('click','.sage-testimonials-more:not(.loading)', function(){

    var that = jQuery(this);
    var page = jQuery(this).data('page');
    var newPage = page+1;
    var ajaxurl = that.data('url');
    var value = that.data('value');

    //prevent user double clicking and animate
    that.addClass('loading');
    that.find('.sage-icon').addClass('spin');

    jQuery.ajax({
      url : ajaxurl,
      type : 'post',
      data : {
        page : page,
        value : value,
        action: 'sage_testimonials_more'
      },
      error : function( response ){
        console.log(response);
      },
      success : function( response ){
        if( response == 0 ){
          that.slideUp(320);
        } else {
          setTimeout(function(){
            jQuery('#testimonials .commentlist').append( response );

            that.data('page', newPage);
            that.removeClass('loading');
            that.find('.sage-icon').removeClass('spin');
          }, 1000);
        }
      }
    });
  });

  /* AJAX - WholseSale Add To Cart Buttons*/
  jQuery(document).on('click', '.custom_add_to_cart', function(e) {
    e.preventDefault();
     jQuery('.menu-cart').removeClass('animate_Swipe');
	if (jQuery(this).hasClass('simple-button')){
	  var productID = jQuery(this).next().attr('value');
	  var quantity = jQuery("li.post-"+productID+" input[name='quantity']").val();
	  var data = {
		  product_id: productID,
		  quantity: quantity
	  };
	}
	else{
	  var variableID = jQuery(this).next().next().next().attr('value');
	  var productID = jQuery(this).next().next().attr('value');
	  var quantity = jQuery("li.post-"+productID+" input[name='quantity']").val();
	  var data = {
		  product_id: variableID,
		  quantity: quantity
	  };
	}
    
    jQuery(this).parent().addClass('loading');
    jQuery('.custom_add_to_cart').attr('disabled', true);
    jQuery.post(wc_add_to_cart_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'), data, function (response) {

      if (!response) {
        return;
      }
      if (response.error) {
        alert("There was an error adding the item. Please refresh the page and try again. If the issue continues please contact the website owner.");
        jQuery('.custom_add_to_cart').parent().removeClass('loading');
        return;
      }
      if (response) {
        var url = woocommerce_params.wc_ajax_url;
        url = url.replace("%%endpoint%%", "get_refreshed_fragments");
        jQuery.post(url, function (data, status) {
           jQuery(".woocommerce.widget_shopping_cart").html(data.fragments["div.widget_shopping_cart_content"]);
          if (data.fragments) {
            jQuery.each(data.fragments, function (key, value) {

              jQuery(key).replaceWith(value);
              jQuery("li.post-"+productID+" >p").css('display', 'none');
              jQuery('.custom_add_to_cart').removeClass('loading_wholesale');
              jQuery('.custom_add_to_cart').attr('disabled', false);
               jQuery('.menu-cart').addClass('animate_Swipe');
               
            });
          }
          jQuery("body").trigger("wc_fragments_refreshed");
        });
        jQuery('.custom_add_to_cart').parent().removeClass('loading');

      }
    });
  });

  /*/ scroll functions /*/
  jQuery(window).scroll( function(){

    var scroll = jQuery(window).scrollTop();

    if( Math.abs( scroll - last_scroll ) > jQuery(window).height()*0.1 ) {
      last_scroll = scroll;

      jQuery('.page-limit').each(function( index ){

        if( isVisible( jQuery(this) ) ){

          history.replaceState( null, null, jQuery(this).attr("data-page") );
          return(false);
        }
      });
    }
  });

  function isVisible( element ){
    var scroll_pos = jQuery(window).scrollTop();
    var window_height = jQuery(window).height();
    var el_top = jQuery(element).offset().top;
    var el_height = jQuery(element).height();
    var el_bottom = el_top + el_height;

    return ( (el_bottom - el_height*0.25 > scroll_pos) && (el_top < ( scroll_pos+0.5*window_height )) );
  }

  /*/ sidebar functions /*/
  jQuery(document).on('click', '.js-toggleSidebar', function() {
    jQuery( '.sage-sidebar' ).toggleClass( 'sidebar-closed' );
    jQuery( 'body' ).toggleClass( 'no-scroll' );
    jQuery( '.sidebar-overlay' ).fadeToggle( 320 );
  });

  /*/ contact form submission /*/
  jQuery('#sageContactForm').on('submit', function(e){
    e.preventDefault();

    jQuery('.has-error').removeClass('has-error');
    jQuery('.js-show-feedback').removeClass('js-show-feedback');

    var form = jQuery(this),
    name = form.find('#fname').val() + ' ' + form.find('#lname').val(),
    email = form.find('#email').val(),
    subject = form.find('#subject').val(),
    message = form.find('#message').val(),
    ajaxurl = form.data('url');

    if( form.find('#fname').val() === '' ){
      jQuery('#fname').parent('.form-group').addClass('has-error');
      jQuery('.js-form-error').text('Your first name is required.');
      jQuery('.js-form-error').addClass('js-show-feedback');
      return;
    }

    if( email === '' ){
      jQuery('#email').parent('.form-group').addClass('has-error');
      jQuery('.js-form-error').text('Your email is required.');
      jQuery('.js-form-error').addClass('js-show-feedback');
      return;
    }

    if( message === '' ){
      jQuery('#message').parent('.form-group').addClass('has-error');
      jQuery('.js-form-error').text('Please fill out the message field.');
      jQuery('.js-form-error').addClass('js-show-feedback');
      return;
    }

    form.find('input, button, textarea').attr('disabled','disabled');
    jQuery('.js-form-submission').addClass('js-show-feedback');

    jQuery.ajax({
      url : ajaxurl,
      type : 'post',
      data : {

        name : name,
        email : email,
        subject : subject,
        message : message,
        action: 'sage_save_user_contact_form'

      },
      error : function( response ){
        jQuery('.js-form-submission').removeClass('js-show-feedback');
        jQuery('.js-form-error').addClass('js-show-feedback');
        form.find('input, button, textarea').removeAttr('disabled');
      },
      success : function( response ){
        setTimeout(function(){
          jQuery('.js-form-submission').removeClass('js-show-feedback');
          jQuery('.js-form-success').addClass('js-show-feedback');
          form.find('input, button, textarea').removeAttr('disabled').val('');
        },1500);
      }
    });

  });


  //removes text past the 110 character limit and adds ... (For homepage > oils section)
  var i;
  var divs = jQuery(".oil-box-text");
  for(i=0;i<divs.length;i++) {
    if(divs[i].className == 'oil-box-text') {
      divs[i].innerHTML = divs[i].innerHTML.substring(0,110) + ' ...';
    }
  }

  //reviews character limit
  var text_max = 300;
  jQuery('#textarea_feedback').html(text_max + ' characters remaining');

  jQuery('#comment').keyup(function() {
    var text_length = jQuery('#comment').val().length;
    var text_remaining = text_max - text_length;

    jQuery('#textarea_feedback').html(text_remaining + ' characters remaining');
  });

  //Submitting review - Knowledge submission
  jQuery(document).on('click', '.comment-form .form-submit .submit', function() {
    alert("Thank you for submitting a review. It will appear once it's been approved by an admin.");
  });

  //Increase and Decrease buttons for Single Product
  jQuery('.single-product-qty-button1').click( function()
  {
    var valuecounter = jQuery(":input[name='quantity']").val();
    if(valuecounter == 1){
      return;
    }
    else{
      valuecounter--;
      jQuery(":input[name='quantity']").val(valuecounter);
    }

  });

  jQuery('.single-product-qty-button2').click( function()
  {
    var valuecounter = jQuery(":input[name='quantity']").val();
    valuecounter++;
    jQuery(":input[name='quantity']").val(valuecounter);
  });

  jQuery(".form-control").attr('maxlength','700');

  //Smooth Scrolling for the #Links
  jQuery('a[href*=#]:not([href=#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
    || location.hostname == this.hostname) {

      var target = jQuery(this.hash);
      target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        jQuery('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });

  //Detects Scroll - Adds Fixed to Multiple Elements - (Nav, Burger, Search, Cart)
  var stickyNav = jQuery('.main-navigation').offset().top;

  jQuery(window).scroll(function() {
    if (jQuery(window).scrollTop() >= stickyNav / 1.2) {
      jQuery('.main-navigation').addClass('sticky-scroll');
      jQuery('#search-container').addClass('sticky-search');
      jQuery('.sidebar-open').addClass('sticky-burger');
      if (jQuery("#page").width() < 1281 && jQuery("#page").width() > 961 ) {
        jQuery('.right:last-of-type()').addClass('sticky-cart');
      }
    }
    else {
      jQuery('.main-navigation').removeClass('sticky-scroll');
      jQuery('#search-container').removeClass('sticky-search');
      jQuery('.sidebar-open').removeClass('sticky-burger');
      jQuery('.right').removeClass('sticky-cart');
    }
  });

  //Animates for the cart when an item is added via Ajax
  jQuery('.custom_add_to_cart ').click(function(){

    var productID = jQuery(this).next().next().attr('value');
    jQuery("li.post-"+productID+" >p").css('display', 'block');

    jQuery(this).addClass('loading_wholesale');

  });

  //Adds button stlying to the comment form submit
  jQuery(".single .form-submit .submit").addClass('c2c');
  jQuery(".single_add_to_cart_button").addClass('c2c');
  jQuery(".product_type_variable.add_to_cart_button").addClass('c2c');
  jQuery(".product_type_simple.add_to_cart_button").addClass('c2c');
  jQuery(".product_type_variation.add_to_cart_button").addClass('c2c');
  jQuery(".woocommerce-account .woocommerce-Button").addClass('c2c');
   jQuery(".woocommerce-address-fields p button").addClass('c2c');

  jQuery(".single_add_to_cart_button").removeClass('button');
  jQuery(".product_type_variable.add_to_cart_button").removeClass('button');
  jQuery(".product_type_simple.add_to_cart_button").removeClass('button');
  jQuery(".product_type_variation.add_to_cart_button").removeClass('button');
  jQuery(".woocommerce-account .woocommerce-Button").removeClass('button');
   jQuery(".woocommerce-address-fields p button").removeClass('button');
});

//Quantity Buttons - Customize
function increaseValue(){
  document.getElementById('customize-counter').value++;
}
function decreaseValue(){
  if( document.getElementById('customize-counter').value > 1){
    document.getElementById('customize-counter').value--;
  }
  else{
    return;
  }
}

/*/ Browser Support - Delete once Edge supports object-fit /*/
var browser = function() {
  // Return cached result if avalible, else get result then cache it.
  if (browser.prototype._cachedResult)
  return browser.prototype._cachedResult;

  // Opera 8.0+
  var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1+
  var isChrome = !!window.chrome && !!window.chrome.webstore;

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;

  if(isEdge) {
    jQuery('.images').css('height', 'auto');
  }

  return browser.prototype._cachedResult =
  isOpera ? 'Opera' :
  isFirefox ? 'Firefox' :
  isSafari ? 'Safari' :
  isChrome ? 'Chrome' :
  isIE ? 'IE' :
  isEdge ? 'Edge' :
  "Don't know";
};
